<template>
  <div
    :class="{'no-display py-30': !active, 'fs': type == 'fullscreen', 'fixed': type == 'fixed' }"
    :style="styles"
  >
    <span>
        <div class="d-flex justify-content-center align-items-center">
            <span class="atbd-spin-dots spin-lg">
                <span class="spin-dot badge-dot dot-primary"></span>
                <span class="spin-dot badge-dot dot-primary"></span>
                <span class="spin-dot badge-dot dot-primary"></span>
                <span class="spin-dot badge-dot dot-primary"></span>
            </span>
        </div>
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["active", "styles", 'type'],
  components: {
  },
};
</script>

<style scoped>
.fs {
  left: 0;
  top: 0;
  position: absolute;
  height: 100vh;
  display: flex;
  width:100%;
  background: #94949421;
}
.fixed{
    background: #94949421;
    margin: 0 -15px;
    position: fixed;
    width: calc(100% - 280px);
    height: 100%;
    z-index: 9999;
    justify-content: center;
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 1150px) {
  .fixed{
    width: 100% !important;
  }
}

</style>
